import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { PaymentData, PaymentType } from 'src/app/enums/PaymentType';
import { OrderDetailsRes } from 'src/app/models/getOrderDetails';
import { PaymentOption } from 'src/app/models/paymentOption';
import { CheckoutService } from 'src/app/services/checkout.service';
import { PaymentProcessService } from 'src/app/services/payment-process.service';

@Component({
  selector: 'app-payment-methods',
  standalone: false,
  templateUrl: './payment-methods.component.html',
  styleUrl: './payment-methods.component.scss',
})
export class PaymentMethodsComponent {
  selectedPaymentOptionId: number = 0;
  selectedPaymentOption: PaymentOption | undefined;
  cashBackEnable: boolean = false;

  fullCashBack: boolean = false;
  isRequestPending: boolean = false;
  isAllowApplePay: boolean = false;
  termsValue: boolean = false;
  totalAmount: number = 0;
  orderId: number = 0;

  paymentType = PaymentType;
  selectedPaymentType: PaymentType = PaymentType.Card;
  paymentData: PaymentData | undefined;

  referralOffer: { code: string; id: number } | undefined;

  destroyRef = inject(DestroyRef);

  constructor(
    private checkoutService: CheckoutService,
    private paymentProcessService: PaymentProcessService
  ) {}

  ngAfterViewInit(): void {
    if ((window as any).ApplePaySession) {
      this.isAllowApplePay = true && window.self === window.top;

      this.paymentProcessService.saveIsApplePayAllowed(true);
    }
  }

  ngOnInit(): void {
    this.subscribeOrderDetails();
    this.subscribePaymentData();
  }

  get firstPayment(): number | undefined {
    return this.cashBackEnable
      ? this.selectedPaymentOption?.paidAmountNowAfterWallet
      : this.selectedPaymentOption?.paidAmountNow;
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(take(1))
      .subscribe((orderDetailsRes: OrderDetailsRes) => {
        this.totalAmount = orderDetailsRes?.data.totalAmount;
        this.orderId = orderDetailsRes?.data.orderId;
      });
  }

  subscribePaymentData() {
    this.paymentProcessService.paymentData
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((paymentData: PaymentData) => {
        this.paymentData = paymentData;
        this.selectedPaymentOptionId = paymentData.selectedPaymentOptionId;
        this.selectedPaymentOption = paymentData.selectedPaymentOption;
        this.cashBackEnable = paymentData.cashBackEnable;
        this.fullCashBack = paymentData.fullCashBack;
        this.referralOffer = paymentData.referralOffer;
      });
  }

  onChangePaymentType(id: PaymentType) {
    this.selectedPaymentType = id;
    this.paymentProcessService.saveSelectedPaymentType(id);
  }

  startPaymentOperation() {
    this.paymentProcessService.startPaymentOperation();
  }
}
