import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentProcessService } from 'src/app/services/payment-process.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-promo-code',
  standalone: false,
  templateUrl: './promo-code.component.html',
  styleUrl: './promo-code.component.scss',
})
export class PromoCodeComponent implements OnChanges {
  @Input() referralOffer?: { code: string; id: number };
  @Input() orderId!: number;

  promoCodeForm: FormGroup;
  promoCodeApplied = false;
  errorMessage = '';

  get promoCodeControl() {
    return this.promoCodeForm.controls['promoCode'];
  }

  constructor(
    private paymentService: PaymentService,
    private paymentProcessService: PaymentProcessService
  ) {
    this.promoCodeForm = new FormGroup({
      promoCode: new FormControl('', [
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['referralOffer'] && this.referralOffer) {
      this.promoCodeForm.get('promoCode')?.setValue(this.referralOffer.code);
      this.handleAppliedPromoCode(this.referralOffer.id.toString());
    }
  }

  checkPromoCode() {
    this.paymentService
      .checkPromoCode(this.promoCodeForm.value.promoCode, this.orderId)
      .subscribe({
        next: (res) => {
          if (res.data.offerId) {
            this.handleAppliedPromoCode(res.data.offerId);
          } else {
            this.errorMessage = res.data.errorMessage;
            this.promoCodeForm.get('promoCode')?.setErrors({ invalid: true });
          }
        },
        error: (err) => {
          const errorMessage = err.error.message;
          const errors = err.error.errors;

          if (errorMessage) {
            this.errorMessage = errorMessage;
          } else if (errors) {
            const firstErrorKey = Object.keys(errors)[0];
            this.errorMessage = errors[firstErrorKey].join(', ');
          }

          this.promoCodeForm.get('promoCode')?.setErrors({ invalid: true });
        },
      });
  }

  handleAppliedPromoCode(id: string) {
    this.paymentProcessService.saveOfferId(id);
    this.promoCodeApplied = true;
    this.errorMessage = '';
  }

  handleEnterPress() {
    if (this.promoCodeForm.valid) {
      this.checkPromoCode();
    }
  }

  removePromoCode() {
    this.paymentProcessService.saveOfferId('');
    this.promoCodeForm.reset();
    this.promoCodeApplied = false;
  }
}
