<p class="section-title">{{ "promo-code" | translate }}</p>
<form
  class="promo-code"
  [formGroup]="promoCodeForm"
  (ngSubmit)="checkPromoCode()"
  (keydown.enter)="handleEnterPress()"
>
  <div class="input-container">
    @if(promoCodeApplied) {
    <div class="applied-promo-code">
      {{ promoCodeForm.get("promoCode")?.value | shorten }}
      <img
        src="assets/images/clear-promo-code.svg"
        alt="close"
        class="close-icon ms-2"
        (click)="removePromoCode()"
        (keydown.enter)="removePromoCode()"
      />
    </div>
    }

    <input
      type="text"
      class="form-control"
      [placeholder]="'insert-promo-code' | translate"
      formControlName="promoCode"
      [ngClass]="{
        'is-invalid-code':
          promoCodeControl.hasError('invalid') ||
          promoCodeControl.hasError('minlength') ||
          promoCodeControl.hasError('maxlength'),
        'is-valid-code': promoCodeApplied || promoCodeControl.valid
      }"
      [readonly]="promoCodeApplied"
    />

    @if(promoCodeApplied) {
    <button type="button" class="applied-button button">
      <img
        src="assets/images/white-check.svg"
        alt="check"
        class="me-1"
        height="7px"
      />
      {{ "applied" | translate }}
    </button>
    } @else {
    <button
      class="apply-button button"
      [disabled]="promoCodeControl.value.length !== 6"
    >
      {{ "apply" | translate }}
    </button>
    }
  </div>

  @if(promoCodeControl.hasError('invalid')){
  <div class="col-12 mt-1 invalid-code">
    {{ errorMessage }}
  </div>
  } @else if (promoCodeControl.hasError('minlength') ||
  promoCodeControl.hasError('maxlength')) {
  <div class="col-12 mt-1 invalid-code">
    {{ "promo-code-length-validation" | translate }}
  </div>
  } @else if (promoCodeApplied) {
  <div class="col-12 mt-1 valid-code">
    {{ "promo-code-applied" | translate }}
  </div>
  }
</form>
